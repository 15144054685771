import React, { useState } from 'react';
import ThemeTogglerButton from './ThemeTogglerButton';
import './Nav.css'

function Nav() {
    const [showLinks, setShowlinks] = useState(false)

    const handleShowLinks = () => {
        setShowlinks(!showLinks)
    }
    return (
        <nav className={`navbar ${showLinks ? "show-nav" : "hide-nav"} `}>
            <div className='navbar_logo'>
                <a href="/">
                    <div>La Cabane</div>
                    <div>Restaurant d'altitude</div>
                </a>
            </div>
            <ul className='navbar_links'>
                <li className='navbar_item'>
                    <a href="/#" className='navbar_link' onClick={handleShowLinks}>
                        Accueil
                    </a>
                </li>
                <li className='navbar_item'>
                    <a href="/#lacarte" className='navbar_link' onClick={handleShowLinks}>
                        La carte
                    </a>
                </li>
                <li className='navbar_item'>
                    <a href="/#contact" className='navbar_link' onClick={handleShowLinks}>
                        Contact
                    </a>
                </li>
                <li className='navbar_item resa'>
                    <a href="tel:+33611332726" className='navbar_link btn btn-light resa_color' onClick={handleShowLinks}>
                    Réservations: <br />+33 6 11 33 27 26
                    </a>
                </li>
            </ul>
            <div className='theme_button'>
                <ThemeTogglerButton />
            </div>
            <button className='navbar_burger' onClick={handleShowLinks}>
                <span className='burger_bar'></span>
            </button>
        </nav>

    )
}

export default Nav;