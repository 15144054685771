import React from 'react';
import './Contact.css'

export default function Contact() {
    return (
        <section className="contact" id="contact">
            <div className="contact-text">
                <h2 className="contact-title text-center">Nous Contacter</h2>
                <p className="contact-body">Votre hôte Gwladys est prête à vous recevoir chaleureusement pour toutes réservations ou demande d'informations complémentaires.</p>

                <div>
                    <div className='contact-card info'>
                        <div className='row mt-1'>
                            <div className='col'>Adresse</div>
                            <div className='col'>Demandols, 06660 Auron, Saint-Etienne-de-Tinée France</div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col'>Téléphone</div>
                            <div className='col'><a href="tel:+33611332726" className='btn btn-secondary'>+33 6 11 33 27 26</a></div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col'>Courriel</div>
                            <div className='col'><a href="mailto:gwladys06660@gmail.com" className='btn btn-secondary'>gwladys06660@gmail.com</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

