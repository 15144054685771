import './Menu.css'
import FoodCard from './FoodCard'
import Accordion from 'react-bootstrap/Accordion';
import Dishes from './Dishes';

//import assiette_gourmande from '../images/assiette_gourmande.jpg'
//import magret_de_canard_roti from '../images/magret_de_canard_roti.jpg'
import gnocchi from '../images/gnocchi.jpg'


export default function Menu() {
    return (
        <section className="menu" id="lacarte">
            <div className='blur-card'>
                <h2 className='section-title text-center'>A la carte</h2>
                <p className='section-subtitle text-center'>Les suggestions</p>
            </div>
            <div className='menu_container'>
                <FoodCard
                    title="Reblochonnade"
                    mealType="Plats gratinés au feu de bois"
                    description="Reblochon accompagné de sa planche de charcuterie et pommes de terre"
                    price="25"
                />
                <FoodCard
                    title="Salade du berger"
                    mealType="Plats"
                    description="Mélange de salade, tomate, tartine gratinée à la tomme de vache, poulet pané, cromesquis de chèvre , antipasti et  jambon cru"
                    price="18"
                />
                <FoodCard
                    title="Entrecôte"
                    mealType="Viandes"
                    description="Viande de France accompagnée de ses frites"
                    price="26"
                />
                <FoodCard
                    title="Burger tradition"
                    mealType="Burgerfrites"
                    description="Pain rustique, cheddar, oignons, steak 150gr, sauce maison"
                    price="16"
                />
                <FoodCard
                    source={gnocchi}
                    title="Gnocchis"
                    mealType="Plats gratinés au feu de bois"
                    description="Sauce Gorgonzola ou forestière"
                    price="18"
                />
            </div>
            <div className='blur-card mb-2'>
                <h2 className='section-title text-center'>La carte complete</h2>
                <p className='section-subtitle text-center'>Tous nos plats</p>
                <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Plats</Accordion.Header>
                        <Accordion.Body>
                            <Dishes
                                title="Soupe du moment"
                                description="et sa tartine gratinée à la tomme de vache"
                                price="14"
                            />
                            <Dishes
                                title="Salade du berger"
                                description="Mélange de salade, tomate, tartine gratinée à la tomme de vache, poulet pané, cromesquis de chèvre , antipasti et  jambon cru"
                                price="18"
                            />
                            <Dishes
                                title="Planche des alpages à partager"
                                description="Assortiment de charcuterie et fromages"
                                price="28"
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Plats gratinés au feu de bois</Accordion.Header>
                        <Accordion.Body>
                            <Dishes
                                title="Tartiflette"
                                description="Pomme de terre, lardons, oignons, crème, reblochon et fromage fondu"
                                price="18"
                            />
                            <Dishes
                                title="Croziflette"
                                description="Crozet, lardons, oignons, crème, reblochon et fromage fondu"
                                price="18"
                            />
                            <Dishes
                                title="Lasagne"
                                description="Pâte, bolognaise, bechamel et fromage fondu"
                                price="18"
                            />
                            <Dishes
                                title="Gnocchis"
                                description="Sauce forestière ou gogonzola"
                                price="18"
                            />
                            <Dishes
                                title="Mont d'Or"
                                description="Accompagné de sa planche de charcuterie et pommes de terre"
                                price="26"
                            />
                            <Dishes
                                title="Reblochonnade"
                                description="Reblochon accompagné de sa planche de charcuterie et pommes de terre"
                                price="25"
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Viandes</Accordion.Header>
                        <Accordion.Body>
                            <Dishes
                                title="Entrecôte"
                                description="Sauce forestière ou gorgonzola accompagnée de frite"
                                price="26"
                            />
                            <Dishes
                                title="Tartare de Boeuf (non préparé)"
                                description="Condiments, frite, salade"
                                price="20"
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Burgers frites</Accordion.Header>
                        <Accordion.Body>
                            <Dishes
                                title="Burger tradition"
                                description="Pain rustique, cheddar, oignons, steak 150gr, sauce maison"
                                price="16"
                            />
                            <Dishes
                                title="Burger Savoyard"
                                description="Pain rustique, cheddar, reblochon, oignons, steak 150gr, sauce maison"
                                price="18"
                            />
                            <Dishes
                                title="Burger Chèvre"
                                description="Pain rustique, cheddar, chèvre, oignons, steak 150gr, sauce maison"
                                price="18"
                            />
                            <Dishes
                                title="Burger Rustique"
                                description="Pain rustique, cheddar, tomme de vache, oignons, steak 150gr, jambon grillé, sauce maison"
                                price="20"
                            />
                            <Dishes
                                title="Burger Végétarien"
                                description="Pain rustique, cheddar, galette de légumineuses, galette de pommes de terre, oignons, sauce maison"
                                price="16"
                            />



                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Desserts</Accordion.Header>
                        <Accordion.Body>
                            <Dishes
                                title="Tiramisu Kinder"
                                price="8"
                            />
                            <Dishes
                                title="Gaufre sucre et chantilly"
                                price="5"
                            />
                            <Dishes
                                title="Gaufre nutella et chantilly"
                                price="6"
                            />
                            <Dishes
                                title="Brownie"
                                price="6"
                            />
                            <Dishes
                                title="Crème brulée"
                                price="6,50"
                            />
                            <Dishes
                                title="Crème brulée flambée au genépi"
                                price="7,50"
                            />
                            <Dishes
                                title="Tarte du moment"
                                price="8"
                            />
                            <Dishes
                                title="Tarte tatin"
                                price="8"
                            />
                            <Dishes
                                title="Tarte tatin flambée"
                                price="9"
                            />
                            <Dishes
                                title="Crêpe sucre et chantilly"
                                price="5"
                            />
                            <Dishes
                                title="Crêpe nutella et chantilly"
                                price="6"
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <p className='section-subtitle text-center mt-3'>Menu Enfant</p>
                <div className='contact-card info my-2'>
                    <div className='text-center'>

                        <p>Steak haché ou Poulet pané</p>
                        <p>+</p>
                        <p>Frites</p>
                        <p className='price'>12 €</p>
                    </div>
                </div>
                <p className='section-subtitle text-center'>Les boissons</p>
                <Accordion defaultActiveKey="5" flush>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Boissons chaudes</Accordion.Header>
                        <Accordion.Body>
                            <Dishes
                                title="Café"
                                price="2"
                            />
                            <Dishes
                                title="Café allongé"
                                price="2"
                            />
                            <Dishes
                                title="Double expresso"
                                price="3,50"
                            />
                            <Dishes
                                title="Thé, Infusion"
                                price="3"
                            />
                            <Dishes
                                title="Chocolat chaud"
                                price="3,50"
                            />
                            <Dishes
                                title="Vin chaud"
                                price="3,50"
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>Boissons</Accordion.Header>
                        <Accordion.Body>
                            <Dishes
                                title="Coca, Coca Zéro"
                                price="3,50"
                            />
                            <Dishes
                                title="Orangina"
                                price="3,50"
                            />
                            <Dishes
                                title="Oasis"
                                price="3,50"
                            />
                            <Dishes
                                title="Ice Tea"
                                price="3,50"
                            />
                            <Dishes
                                title="Sprite"
                                price="3,50"
                            />
                            <Dishes
                                title="Perrier"
                                price="3"
                            />
                            <Dishes
                                title="Jus de fruits"
                                price="3,50"
                            />
                            <Dishes
                                title="Evian (50cl)"
                                price="3,50"
                            />
                            <Dishes
                                title="San pellegrino (50cl)"
                                price="3,50"
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>Bières et Alcools *</Accordion.Header>
                        <Accordion.Body>
                            <Dishes
                                title="Heineken 33cl"
                                price="4"
                            />
                            <Dishes
                                title="Bière du comté de Nice 33cl"
                                description="Blonde, Blanche, Ambrée"
                                price="6"
                            />
                            <Dishes
                                title="Ricard 4cl"
                                price="4"
                            />
                            <Dishes
                                title="Whisky Jack Daniels 5cl"
                                price="5"
                            />
                            <Dishes
                                title="Rhum Niçois Tue Diable 5cl"
                                price="5"
                            />
                            <Dishes
                                title="Génépi 4cl"
                                price="5"
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>Vins *</Accordion.Header>
                        <Accordion.Body>
                            <Dishes
                                title="Minuty 75cl"
                                description="AOP côte de Provence (Rouge, Rosé, Blanc)"
                                price="30"
                            />
                            <Dishes
                                title="Terre de Berne 75cl"
                                description="AOP côte de Provence (Rouge, Rosé, Blanc)"
                                price="30"
                            />
                            <Dishes
                                title="Gigondas 75cl"
                                description="AOP côte du Rhône (Rouge)"
                                price="38"
                            />
                            <Dishes
                                title="Vacqueras 75cl"
                                description="AOP côte du Rhône (Rouge)"
                                price="38"
                            />
                            <Dishes
                                title="Le Vigneron Savoyard 75cl"
                                description="Apremont AOC (Blanc)"
                                price="26"
                            />
                            <Dishes
                                title="Chablis (Les Beaumonts) 75cl"
                                description="Bourgogne (Blanc)"
                                price="32"
                            />

                                                        <Dishes
                                title="Bellet (Domaine de la source) 75cl"
                                description="Vin de Nice (Rouge, Rosé, Blanc)"
                                price="40"
                            />
                                                        <Dishes
                                title="Château Rasque (côte de Provence)"
                                description="Domaine Saint Pons"
                            />
                                                                                    <Dishes
                                title="Verre 12,5cl"
                                price="3,5"
                            />
                                                                                                                <Dishes
                                title="Pichet 25cl"
                                price="5"
                            />
                                                                                                                                            <Dishes
                                title="Pichet 50cl"
                                price="8"
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className='mt-3'>
                    <p>PRIX NETS - service compris - liste des allergènes disponible à l'acceuil</p>
                    <p>Règlement: accepte espèce/chèque/CB/TR/Ancv</p>
                    <p>TVA 10% sur les boissons non alcoolisées - TVA 20% sur les vins et alcools</p>
                    <p>Affiliation d'origine controlée sauf indication contraire.</p>
                    <p>* L'abus d'alcool est dangereux pour la santé, à consommer avec modération.</p>
                    </div>
            </div>
        </section>
    )
}