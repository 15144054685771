import React from 'react';
import './Footer.css'

export default function Footer () {
    return (
		<footer className="footer">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 footer_col">
						<div className="footer_logo">
							<div className="footer_logo_title">La Cabane</div>
							<div className="footer_logo_subtitle">Restaurant d'altitude</div>
						</div>
					</div>
					<div className="col-lg-6 footer_col">
						<div className="footer_about">
							<ul>
								<li>
									<a href="/">Accueil</a>
								</li>
								<li>
									<a href="/termsofservice">Mentions légales</a>
								</li>
							</ul>
						</div>
						<div className="copyright">
							Copyright &copy; All rights reserved
						</div>
					</div>
				</div>
			</div>
		</footer>
    )
}