import packageJson from '../package.json';
import { createContext, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Nav from './components/Nav';
import Footer from "./components/Footer";
import CookieConsent from "react-cookie-consent";




import Home from './pages/Home';
import TermsOfService from './pages/TermOfService';

import './App.scss'

export const ThemeContext = createContext(null);

function App() {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  console.info(`Version:`,packageJson.version);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme}}>
    <div className="App" data-bs-theme={theme} id={theme}>
        <Nav  />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/termsofservice" element={<TermsOfService />} />
          </Routes>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="J'ai compris"
          cookieName="LacabaneCookie"
          style={{ background: "#232323" }}
          disableStyles={false}
          buttonClasses="btn btn-primary"
          containerClasses="alert alert-primary col-lg-12"
          buttonStyle={{
            background: "#ff1122",
            color: "white",
            fontWeight: "bolder",
          }}
        
          expires={150}
        >
          Nous utilisons des cookies afin d'être sûr que vous pouvez avoir la meilleure expérience sur notre site. Si vous continuez à utiliser ce site, nous supposons que vous acceptez.
        </CookieConsent>
    </div>
    </ThemeContext.Provider>
  );
}

export default App;
